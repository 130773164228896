





















































import {Component, Vue} from 'vue-property-decorator';
import {PublicBoardTemplates} from "@/graphql/boardTemplate";
import WorkspaceSidebarLoader from "@/components/loaders/WorkspaceSidebarLoader.vue";


@Component({
  components: { WorkspaceSidebarLoader },
  apollo: {
    PublicBoardTemplates: {
      query: PublicBoardTemplates,
    },
  },
})
export default class TemplatesLayout extends Vue {
  loading: boolean = true;

  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1500);
  }
}
